import request from './request.js'
export function monthlyStaticsList(params) {
    return request({
       url:"/statistics/mealMonth/ListMonthFlow.do?",
        method: 'post',
        data: params
    })
}

export function getMealMonthName(params) {
    return request({
       url:"/statistics/mealMonth/getMealName.do?",
        method: 'post',
        data: params
    })
}

export function getMealMonthDetail(params) {
    return request({
       url:"/statistics/mealMonth/getDetails.do?",
        method: 'post',
        data: params
    })
}

export function exportMealMonthDetail(params) {
    return request({
       url:"/statistics/mealMonth/exportDetails.do?",
        method: 'post',
        data: params
    })
}


export function packageSaleRecordList(params){
	return request({
		url:"statistics/mealsale/list.do?",
		method:"post",
		data:params
	})
}

/* export function packageSaleExport(params){
	return request({
		url:"record/importExportRecord/limitExportCount.do?",
		method:"post",
		data:params
	})
} */
export function packageSaleExport(params){
	return request({
		url:"flowmonitor/exportStatisSync.do?",
		method:"post",
		data:params,
	})
	}
export function DetailExport(params){
	return request({
		url:"flowmonitor/exportFlowSync.do?",
		method:"post",
		data:params
	})
	}

export function trafficPoolStatics(params){
	return request({
		url:"statistics/adminstatispool/list.do?",
		method:"post",
		data:params
	})
}

export function trafficPoolGetComList(params){
	return request({
		url:"/system/cardOperator/getAccountListByAgent.do?",
		method:"post",
		data:params
	})
}

/* export function trafficPoolExport(params){
	return request({
		url:"record/importExportRecord/limitExportCount.do?",
		method:"post",
		data:params
	})
} */
export function trafficPoolExport(params){
	return request({
		url:"statistics/adminstatispool/export2excelSync.do?",
		method:"post",
		data:params
	})
}

export function mealsaleExport(params){
	return request({
		url:"statistics/mealsale/export2excelSync.do?",
		method:"post",
		data:params
	})
}

export function trafficPoolMemberList(params){
	return request({
		url:"statistics/adminstatispool/memberList.do?",
		method:"post",
		data:params
	})
}

export function useCardTraffic(params){
	return request({
		url:"card/usedFlow.do?",
		method:"post",
		data:params
	})
}


export function packgUsageStatList(params){
	return request({
		url:"flowmonitor/statis.do?",
		method:"post",
		data:params
	})
}

export function packgUsageDetail(params){
	return request({
		url:"flowmonitor/list.do?",
		method:"post",
		data:params
	})
}

export function orderSummary(params){
	return request({
		url:"/statistics/adminstatis/list4everyday.do?",
		method:"post",
		data:params
	})
}

export function orderStatistics(params){
	return request({
		url:"/order/orderStatistics.do?",
		method:"post",
		data:params
	})
}
export function userActive(params){
	return request({
		url:"/statistics/adminstatis/activeStatis.do?",
		method:"post",
		data:params
	})
}

export function daheList(params){
	return request({
		url:"/statis/dahe/mealStatics.do?",
		method:"post",
		data:params
	})
}

export function daheDetailList(params){
	return request({
		url:"/statis/dahe/orderList.do?",
		method:"post",
		data:params
	})
}

export function daheAgentList(params){
	return request({
		url:"/statis/dahe/statics.do?",
		method:"post",
		data:params
	})
}

export function daheAgentDetailList(params){
	return request({
		url:"/statis/dahe/agentOrderList.do?",
		method:"post",
		data:params
	})
}

export function daheExport(params){/* 有bug */
	return request({
		url:"/statis/dahe/agentOrderListSync.do?",
		method:"post",
		data:params
	})
}

export function daheMealExport(params){
	return request({
		url:"/statis/dahe/exportListSync.do?",
		method:"post",
		data:params
	})
}

export function CardDayStaticsList(params){
	return request({
		url:"/statis/dahe/cardDayStatics.do?",
		method:"post",
		data:params
	})
}

export function getAgentList(params){
	return request({
		url:"/agent/list4agent.do?",
		method:"post",
		data:params
	})
}

export function cardCycleList(params){
	return request({
		url:"/statistics/adminstatis/cardCycle.do?",
		method:"post",
		data:params
	})
}

export function mealNumStatics(params){
	return request({
		url:"/statistics/adminstatis/mealNumStatics.do?",
		method:"post",
		data:params
	})
}

export function mealUseStatics(params){
	return request({
		url:"/statistics/adminstatis/mealUseStatics.do?",
		method:"post",
		data:params
	})
}
export function getMealName(params){
	return request({
		url:"/statistics/adminstatis/getMealName.do?",
		method:"post",
		data:params
	})
}

export function cardCycleStaticsList(params){
	return request({
		url:"/statistics/adminstatis/cardCycleStaticsList.do?",
		method:"post",
		data:params
	})
}
export function cardCycleExport(params){
	return request({
		url:"/statistics/adminstatis/cardCycleExportSync.do?",
		method:"post",
		data:params
	})
}

export function cardDayStaticsExport(params){
	return request({
		url:"/statis/dahe/cardDayStaticsExport.do?",
		method:"post",
		data:params
	})
}























